@media screen and (min-width: 1600px) {

}




@media screen and (max-width: 1300px) {
    .short_header {
      display: none;
    }
    .page_container {
        margin-top: 50px;
    }
    .main_preloader{
        top: 50px !important;
    }

  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 52px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      background-color: rgb(224, 224, 224);
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
    }
  
    .nav_logo_mobile {
      display: block;
    }
  
    .nav-menu.active {
      background-color: rgb(224, 224, 224);
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-item {
      margin-right: 0;
    }
  
    .nav-links {
      padding: 0;
      width: 100%;
      display: table;
    }
  
    .nav-dropbtn {
      width: 100%;
      display: table;
      background-color: rgb(224, 224, 224);
      padding: 0px;
      line-height: 50px;
  
    }
  
    .dropdown-content {
      display: none;
      position: relative;
      background-color: var(--light_gray);
      min-width: 180px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
      z-index: 100000;
    }
  
    .drop-nav-links {
      float: none;
      padding: 0;
      text-decoration: none;
      display: block;
      color: #000000;
      font-size: 14px;
      text-align: center;
      background-color: rgb(224, 224, 224);
  
    }
  
    .nav-icon {
      display: block;
      position: relative;
      margin-right: 20px;

      /* transform: translate(-100%, 60%); */
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--orange);
    }
  }



@media screen and (max-width:767px) {

/* ********************* */
/* Footer */
/* ********************* */

.footer {
    margin-top: 0px;
}

.footer-logo-1 img{
    width: 120px;
    padding: 10px;
}

.footer-logo-2 img{
    width: 160px;
    padding: 10px;
}
.footer-menu{
    padding: 5px;
}

.footer-menu a{
    padding: 5px;
    font-size: 12px;
}
.main {
    padding: 0px 0px;
  }
  #map {
    width: calc(100% - 2px);
    height: 60vh;
  }
  /* .leaflet-control-attribution {
    background-color: yellow !important;
    font-size: 12px !important;
    width: 100vw;
  } */


.data-table {
    display: block;
    margin: 2px 0;
  }

  table {
    text-align: center;
    font-size: 12px;
    width: 95%;
  }

  td,
  th {
    border: 1px solid #000000;
    text-align: left;
    padding: 2px;
  }

  .section {
    margin: 20px;
  }
  .section-heading{
    font-size: 14px;
}
.contact-us-form{
    margin: 20px 0;
    padding: 0px;
}
.contact-image{
    margin-bottom: 0px;
}
.contact-image img{
    width: 100%;

}
.contact-image-caption p{
    margin-bottom: 30px;
    text-align: right;
}

.row{
    display: flex;
    margin-top: 0px;
    flex-direction: column;
}
.column-3{
    width: 100%;
}
.column-6{
    width: 100%;
}
/* Time Series */
.Timeseries-container{
    margin: 0px;
    /* width: 100px; */
    display: block;
    text-align: center;
    /* max-width: 1100px; */
}
.sitevisitorContainer a{
    font-size: 14px;
    
  }
  
  .sitevisitorContainer{
    margin: 1rem;
  }
  .sitevisitor-item {
    margin: 1rem auto;
    max-width: 100%;
    max-height: 500px;
    overflow: hidden;
  }
  

}



@media screen and (max-width:500px) {
    /* Legend */
.item-heading{
    font-size: 12px;
  }
.legend {
    padding: 10px 15px;  
}
.map-heading{
    font-size: 10px;

}
.date-heading{
    font-size: 14px;
}

.legend-item{
    display: flex;

}
.legend-color-value{
    font-size: 10px;
}
.legend-color i {
    width: 50%;
    height: 20px;
    border: black 0.5px solid;
}

.legend-num-value{
    font-size: 10px;
}
.report-drought p{
    font-size: 14px;
}
.leaflet-control-attribution {
    background-color: yellow !important;
    font-size: 12px !important;
    font-family: 'Source Sans Pro', sans-serif;
  }
/* .leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
    }
.leaflet-retina .leaflet-control-layers-toggle {
	background-size: 20px 20px !important;
	} */


.news-card {
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    background: var(--light_gray);
    padding: 15px;
    margin: 5px;

}

.news-card-header h4 {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
}




}




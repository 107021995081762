@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&family=EB+Garamond:wght@800&family=Nanum+Myeongjo&family=Open+Sans:wght@300;400;500;600;800&family=Raleway:wght@600&family=Roboto&family=Source+Sans+Pro&family=Ubuntu&display=swap');
/* 
font-family: 'Cormorant Garamond', serif;
font-family: 'EB Garamond', serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Raleway', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Ubuntu', sans-serif;
*/


/* ********************* */
/* General */
/* ********************* */

* {
    padding: 0;
    margin: 0;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
  
}
.page_container{
    margin-top: 100px;

}



:root {
    --orange: #FF7F3F;
    --light_gray: rgb(244, 243, 243);
    --dark_gray: rgb(219, 219, 219);
}

a {
    text-decoration: none;
}

.section-heading {
    font-size: 18px;
    padding: 10px;
    color: var(--orange);
    margin: 20px 0px;
    font-family: "Open sans", Helvetica, Arial;
    border-bottom: 2px solid rgb(198, 198, 198);
}

.section {
    margin: 50px;
}

.row {
    display: flex;
    margin-top: 20px;
}

.column-3 {
    width: 25%;
    /* margin: 0px auto; */
}

.column-6 {
    width: 50%;
}

.content-heading {
    font-size: 24px;
    margin: 20px 0;
}

.para-content {
    text-align: justify;
    font-size: 18px;
}

.para-content a {
    color: rgb(34, 137, 248);
}


/* ********************* */
/* Navbar */
/* ********************* */


.whole_navbar_container {
    position: fixed;
    top: 0px;
    z-index: 10000;
    width: 100%;
    left: 0px;
    right: 0px;
  }
  
  .short_header {
    color: var(--orange);
    font-size: 14px;
    font-weight: 800;
    font-family: "Open sans", Helvetica, Arial;
    text-align: center;
    background-color: rgb(230, 230, 230);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar {
    background-color: var(--light_gray);
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
  
  
  }
  
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
  }
  
  .nav_logo_mobile {
    color: var(--orange);
    font-weight: 800;
    font-family: "Open sans", Helvetica, Arial;
    align-items: center;
    margin-left: 20px;
    font-size: 20px;
    flex-grow: 1;
    display: none;
  
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
  
  }
  
  .nav-links {
    color: black;
    text-decoration: none;
    padding: 15px 10px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    align-items: center;
  }
  
  .nav-links:hover {
    background-color: var(--dark_gray);
  
  }
  
  .nav-dropbtn {
    display: inline-block;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    color: #000000;
    padding: 15px 10px;
    border: none;
    background-color: var(--light_gray);
  }
  
  .nav-dropbtn:hover {
    background-color: var(--dark_gray);
    cursor: pointer;
  
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--light_gray);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 100000;
  }
  
  .drop-nav-links {
    float: none;
    padding: 0px 10px;
    text-decoration: none;
    display: block;
    color: #000000;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    text-align: left;
  
  
  }
  
  .drop-nav-links:hover {
    background-color: var(--dark_gray);
  }
  
  
  
  .nav-item :hover .dropdown-content {
    display: block;
  }
  
  
  
  
  .fa-code {
    margin-left: 1rem;
  }
  
  .nav-item {
    line-height: 50px;
    margin-right: 0.5rem;
  }
  
  
  .nav-item .active {
    background-color: var(--dark_gray);
  }
  
  .nav-icon {
    display: none;
  }




/* ********************* */
/* Map */
/* ********************* */

.main {
    padding: 0px 8%;
    margin-bottom: 100px;
}

.map-heading {
    font-size: 15px;
    color: white;
    padding: 5px;
    background-color: var(--orange);
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.date-heading {
    font-size: 16px;
    color: rgb(10, 10, 10);
    padding: 5px;
    background-color: yellow;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.date-heading p {
    display: inline-block;
}

.date-function {
    display: inline-block;
}

.map-span {
    color: rgb(0, 17, 255);
}

#map {
    width: calc(100% - 2px);
    height: 75vh;
    background-color: white;
    border: 1px solid black;
}

/* Legend */
.legend-panel {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.legend {
    background-color: rgb(247, 238, 216);
    padding: 10px 20px;
    border: none;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: center;
    text-align: center;

}

.item-heading {
    font-size: 15px;
    padding: 5px 0;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
    margin-top: 5px;

}

.legend-item {
    display: flex;

}

.legend-color-value {
    width: 50%;
    text-align: center;
    font-size: 18px;
}

.legend-color {
    display: flex;
}

.legend-color i {
    width: 50%;
    height: 20px;
    border: black 0.5px solid;
}

.legend-num-value {
    width: 16.6%;
    text-align: left;
    font-size: 16px;
}

.leaflet-control-attribution {
    background-color: yellow !important;
    font-size: 18px !important;
    font-family: 'Source Sans Pro', sans-serif;
}


/* Data Table */
.data-table {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
}

table {
    font-size: 16px;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

td,
th {
    border: 1px solid #000000;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #ffeed3e1;
}

/* Time Series */
.Timeseries-container {
    margin: auto;
    width: 100%;
    display: block;
    text-align: center;
    max-width: 1100px;
}

.Loading-tag {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    align-items: center;
    text-align: center;
    color: var(--orange);

}

/* ********************* */
/* Drought Reporting */
/* ********************* */
.report-drought {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.report-drought p {
    font-size: 18px;
    margin: 5px 10px;
    font-family: 'Raleway', sans-serif;


}

.report-drought-form {
    margin: 5px;
    padding: 10px;

}

/* ********************* */
/* Contact Form */
/* ********************* */
.contact-us-form {
    margin-bottom: 20px;
    padding: 10px;
}

.form-control {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    font-family: 'Raleway', sans-serif;
}

.submit-button {
    padding: 10px 15px;
    color: #ffffff;
    background-color: #FF7F3F;
    border: none;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 10px;
}

.submit-button:hover {
    background-color: #fb6114;
    cursor: pointer;
}

.success-message {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    color: var(--orange);
    text-align: center;
    margin-bottom: 20px;
}

.contact-image {
    margin-bottom: 20px;
    padding: 10px;



}

.contact-image img {
    width: 100%;
    max-width: 500px;
    padding: 2px;
    border: 1px solid black;



}

.contact-image-caption p {
    font-size: 16px;
    padding: 10px;
}

.contact-image-caption a {
    color: var(--orange);
    font-size: 20px;
    font-family: "Open sans", Helvetica, Arial;
    font-weight: 600;

}





/* ********************* */
/* Footer */
/* ********************* */


.footer {
    width: 100%;
    box-sizing: border-box;
    background-color: whitesmoke;
    margin-top: auto;
    margin-top: 80px;
    /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.10); */
    align-items: center;
}

.footer-image {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
}

.footer-logo-1 {
    position: relative;
}

.footer-logo-1 img {
    width: 250px;
    padding: 20px;
}

.footer-logo-2 {
    position: relative;
}

.footer-logo-2 img {
    width: 300px;
    padding: 20px;
}

.footer-menu {
    margin-top: 20px;
    padding: 10px;
    text-align: right;
    color: rgb(0, 0, 0);
}

.footer-menu-item {
    padding: 10px;
    color: rgb(0, 0, 0);
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
}

.footer-menu-item:hover {
    color: var(--orange);
}


/* ********************* */
/* News */
/* ********************* */


.news-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.news-card {
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    background: var(--light_gray);
    padding: 20px;
    margin: 10px;

}

.news-card-header {
    font-size: 26px;
    font-family: 'Raleway', sans-serif;
}

.news-tag {
    padding: .25em .75em;
    font-size: 16px;
    background: #56CCF2;
    font-family: 'Raleway', sans-serif;
    background: linear-gradient(to bottom, #2F80ED, #56CCF2);
    color: #fafafa;
}

.news-card-image{
    width: 100%;
}
.news__info  {
    color: rgb(0, 0, 0);
    font-family: 'Raleway', sans-serif;
}

.read-more-btn {
    padding: 10px;
    background-color: var(--orange);
    border: none;
}
.news-pagination{
    border: none;
    padding: 10px 20px;
}
.news-pagination:hover{
    cursor: pointer;
    background-color: #c0bebe;
}


.read-more-btn:hover {
    background-color: rgb(233, 120, 0);
}

.news__info a {
    color: rgb(254, 254, 254);
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
}


.sitevisitorContainer {
    text-align: center;
    margin: 1rem;

  }
  .sitevisitorContainer a{
    text-align: center;
    text-decoration: none;
    color: black;
    font-family: "Open sans", Helvetica, Arial;
    font-size: 20px;
  }
  
  .sitevisitor-item {
    margin: 0 auto;
    max-width: 100%;
    max-height: 500px;
    overflow: hidden;
  }
  
  .sitevisitor-map {
    width: 100%;
    height: 400px;
    border: none;

  }